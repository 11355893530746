import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Solicitantes } from '../models/Solicitantes';
import { Solicitudes } from '../models/Solicitudes';
import { StorageService } from '../storage.service';
// import { Apollo } from 'apollo-angular';
import { graphQLService } from '../graphql/graphQL.service';
import { ConsultarDocumentos } from '../models/dtos/consultarDocumentos.dto';
import { Documentos } from '../models/Documentos';
import { Application } from '../models/Ayudate2.0/Application.entity';
import { loggerService } from './logger.service';

interface createNewApplicationResponse {
  createNewApplication: Application;
}
interface updateApplicationResponse {
  updateApplication: {
    IdApplication: number;
    IdZellApp: number;
    uuid: string;
    person: {
      IdPerson: number;
      uuid: string;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class SolicitudesService {

  constructor(
    private http: HttpService,
    private storage: StorageService,
    // private apollo: Apollo,
    public _graphQLService: graphQLService,
    private _loggerService: loggerService
  ) {

  }
  async cargarDatosCliente(): Promise<any> {
    let solicitudes = await this.storage.getData('solicitudes');
    if (solicitudes == undefined || solicitudes == '') {
      let result = await this.http.get('login/datos_cliente').toPromise();

      await this.storage.setData('solicitante', result['data']['solicitante']);
      await this.storage.setData('solicitudes', result['data']['solicitudes']);
      let zell_user = await this.buscarPersonaZell(result['data']['solicitante'])
      await this.storage.setData('solicitante_zell', zell_user['data']);
      return result;
    } else {
      return null;
    }
  }

  async updateCountSocket (coachId,message) {
    return await this.http.postNest('credit-request/updateCountAlert',{coachId,message});
  }

  async consultarEmpresa(data: Object): Promise<any> {
    let result = await this.http.get('empresa/datos', data).toPromise();
    return result;
  }

  async consultarConveniosEmpresas(data: Object) {

    if(!data['id_convenio']){ 
      console.log(data['id_convenio'])
      return [];
    }

    let result = await this.http.getNest(`specialconditions/${data['id_convenio']}`);
    return result;
  }

  async consultarEmpresasCoach(idCoach: number): Promise<any> {
    let result = await this.http.get('empresa/empresas_coach', { idCoach }).toPromise();
    return result;
  }

  async crearSolicitud(data: Object): Promise<Object> {
    let result = await this.http.post('solicitudes/crear_solicitud', data).toPromise();
    if (result['code'] == 200) {
      delete result['data']['newSol'];

      let IdApplication = 0;
      let IdPerson = 0;
      let IdZellApp = 0;
      let uuid = '';
      let uuidPerson = '';
      let uuidApplication = '';

      let GraphQLVariableName = data['solicitud']['id'] != null ? 'application' : 'createApplicationInput';
      let datagql =
        GraphQLVariableName == 'createApplicationInput' ?
          {
            [GraphQLVariableName]: {
              ...result['data'],
              prospect: localStorage.getItem("prospect") || null
            }
          }
          :
          {
            [GraphQLVariableName]: {
              ...result['data']
            }
          };

      let formatoSolicitante;
      if (data['solicitud']['id'] != null) {
        // * Hasta que el telefono de la persona exista se actualiza la solicitud
        if (data['solicitante']['telefono'] != null) {
          await this._graphQLService.mutate(
            `mutation updateApplication($${GraphQLVariableName}: upsertApplication!) {
              updateApplication(${GraphQLVariableName}: $${GraphQLVariableName}) {
                IdApplication
                uuid
                IdZellApp
                person {
                  IdPerson
                  uuid
                }
              }
            }`, datagql
          )
            .then(result => {
              const data: updateApplicationResponse = result.data as updateApplicationResponse;

              console.log({ IdZellApp: data.updateApplication.IdZellApp })

              IdApplication = data.updateApplication.IdApplication;
              IdPerson = data.updateApplication.person.IdPerson;
              IdZellApp = data.updateApplication.IdZellApp
              uuid = data.updateApplication.person.uuid;
              uuidPerson = data.updateApplication.person.uuid;
              uuidApplication = data.updateApplication.uuid;
              return data
            });

          if (data['solicitud']['step'] != null) {
            GraphQLVariableName = 'createDocumentsApplicationInput';

            await this._graphQLService.mutate(`
                mutation CreateDocumentsApplication($${GraphQLVariableName}: CreateDocumentsApplicationInput!) {
                  createDocumentsApplication(${GraphQLVariableName}: $${GraphQLVariableName}) {
                    IdDocuments_Application
                    State
                    UrlFile
                  }
                }`,
              {
                [GraphQLVariableName]: {
                  "IdApplication": IdApplication,
                  "uuidApplication": uuidApplication,
                  "IdPerson": IdPerson,
                  "uuidPerson": uuidPerson
                }
              }
            )
              .then(({ data }) => {
                return data
              });
          }
        }

      } else {
        // Si entra es porque solo se capturaron los datos para aperturar la solicitud (nombres, fecha y empresa)
        const application = await this._graphQLService.mutate(
          `mutation CreateNewApplication($${GraphQLVariableName}: upsertApplication!) {
            createNewApplication(${GraphQLVariableName}: $${GraphQLVariableName}) {
              IdApplication
              IdZellApp
              uuid
              person {
                IdPerson
                uuid
                }             
            }
          }`, datagql
        )
          .then(result => {
            const data: createNewApplicationResponse = result.data as createNewApplicationResponse;
            return data.createNewApplication;
          });

        IdApplication = application.IdApplication;
        IdPerson = application.person.IdPerson;
        IdZellApp = application.IdZellApp;

        /* // ! Falta sustituir los ID por los de la base de datos ayudate y el formato para BirthDay 
        const person = new PersonMapper();
        formatoSolicitante = person.responseApplicationToPerson(application);
        */
      }

      await this.storage.setData('solicitante', result['data']['solicitante']);
      let solicitudes = await this.storage.getData('solicitudes');
      if (solicitudes != undefined && solicitudes != '') {
        solicitudes[0] = result['data']['solicitud'];
      } else {
        solicitudes = [result['data']['solicitud']];
      }
      solicitudes[0]["id_app"] = IdZellApp;
      await this.storage.setData('solicitudes', solicitudes);
    }
    return result;
  }

  async consultarSolicitante(data: Object): Promise<Object> {
    let result = await this.http.post('solicitudes/consultar_solicitante', data).toPromise();
    if (result['code'] == 200) {
      await this.storage.setData('solicitante', result['data']['solicitante']);
      let solicitudes = [];
      if (result['data']['solicitudes'] != undefined && result['data']['solicitudes'] != '') {
        solicitudes = result['data']['solicitudes'];
      }
      result['data']['solicitudes'] = solicitudes;
      await this.storage.setData('solicitudes', solicitudes);
    }
    return result;
  }

  async consultarSolicitanteActual(data: Object): Promise<Object> {
    let result = await this.http.post('solicitudes/consultar_solicitante_actual', data).toPromise();
    if (result['code'] == 200) {
      await this.storage.setData('solicitante', result['data']['solicitante']);
      let solicitudes = [];
      if (result['data']['solicitudes'] != undefined && result['data']['solicitudes'] != '') {
        solicitudes = result['data']['solicitudes'];
      }
      result['data']['solicitudes'] = solicitudes;
      await this.storage.setData('solicitudes', solicitudes);
    }
    return result;
  }

  async consultarSolicitanteByIdDontUpdateLocal(data: Object): Promise<Object> {
    let result = await this.http.post('solicitudes/consultar_solicitante_id', data).toPromise();
    return result;
  }

  async consultarSolicitanteById(data: Object): Promise<Object> {
    let result = await this.http.post('solicitudes/consultar_solicitante_id', data).toPromise();
    if (result['code'] == 200) {
      await this.storage.setData('solicitante', result['data']['solicitante']);
      let solicitudes = [];
      if (result['data']['solicitudes'] != undefined && result['data']['solicitudes'] != '') {
        solicitudes = result['data']['solicitudes'];
      }
      result['data']['solicitudes'] = solicitudes;
      await this.storage.setData('solicitudes', solicitudes);
    }
    return result;
  }

  async consultarSolicitud(id: number): Promise<Object> {
    let result = await this.http.post('solicitudes/consultar_solicitud', { id }).toPromise();
    if (result['code'] == 200) {
      await this.storage.setData('solicitante', result['data']['solicitante']);
      await this.storage.setData('solicitudes', [result['data']['solicitud']]);
      await this.storage.setData('contrato', [result['data']['contrato']]);
    }
    return result;
  }

  async crearSolicitudZell(solicitud: Solicitudes, solicitante: Solicitantes, isRecarga = null): Promise<Object> {

    let idApp = solicitud.id_app ||  solicitante.id_app;

    if(idApp){
      let statusZell = await this.consultarStatusZell(idApp)
      if(statusZell && statusZell['data'][0] && statusZell['data'][0]['OpStatus'] == 'En proceso'){
        solicitud.id_app = null
        solicitante.id_app = null
      }

    }


    
    let result = await this.http.post('zell/guardar_solicitud', { solicitud, solicitante, isRecarga }).toPromise();

    // let resultNest = await this.http.postNest("zell/saveApplication", { "IdApplication": solicitud.id });
    // console.log({ resultNest });

    return result;
  }

  async consultarTipoContrato(id: number): Promise<Object> {

    // ! No se encontro la ruta en el backend

    let result = await this.http.post('solicitudes/consultar_contrato', { id }).toPromise();
    return result;
  }

  async consultarConvenio(id: number): Promise<any> {
    let result = await this.http.post('solicitudes/consultar_convenio', { id }).toPromise();
    return result;
  }

  async calcularCredito(id_solicitud: number): Promise<Object> {
    let result = await this.http.post('credito/calcular_credito', { id_solicitud }).toPromise();
    return result;
  }

  async reservarenomina(id_solicitud: number, rfc: string, orgaoCodigo: string, clabe: string, convenio_id: number): Promise<Object> {
    console.log({ orgaoCodigo });
    if (orgaoCodigo !== '') {
      let result = await this.http.post('enomina/reservar_enomina', { id_solicitud, rfc, orgaoCodigo, clabe, convenio_id }).toPromise();
      console.log("Resultado de Enomina ", result)
      let solicitudes = await this.storage.getData('solicitudes');
      if (solicitudes != undefined && solicitudes != '') {
        solicitudes[0] = result['data'];
      } else {
        solicitudes = [result['data']];
      }
      await this.storage.setData('solicitudes', solicitudes);
      return result;
    } else {
      return null;
    }
  }

  async reservar_nomipay(idSolicitud: number, rfc: string, monto: string) {
    let result = await this.http.post('nomipay/nomipay_reservar', { idSolicitud, rfc, monto }).toPromise();
    console.log("Resultado de nomipay ", { result })
    if (result['ok']) {
      return result['data'];
    } else {
      return null;
    }

  }

  async buscarPersonaZell(solicitante: Solicitantes): Promise<Object> {
    let result = null;
    let resultNest = null;
    if (solicitante.id_person != undefined) {
      result = await this.http.get('zell/consultar_datos_id',
        { 'iPersonId': solicitante.id_person }).toPromise()

      // resultNest = await this.http.getNest(`zell/getApplicationByPerson/${solicitante.id_person}`);

    } else {
      if (solicitante['apellido_materno'] == '.' || solicitante['apellido_materno'] == null)
        solicitante['apellido_materno'] = '';

      let nacimiento = solicitante['fecha_nacimiento'].split("/");
      let params = {
        vName: `${solicitante['apellido_paterno'].trim()} ${solicitante['apellido_materno'].trim()} ${solicitante['nombre'].trim()} ${solicitante['segundo_nombre'].trim()}`.trim(),
        vBirthDate: `${nacimiento[2]}${nacimiento[1]}${nacimiento[0]}`
      };
      // resultNest = await this.http.postNest("zell/getApplicationByName", params);
      result = await this.http.get('zell/consultar_datos_nombre', params).toPromise();
    }
    return result;
  }

  async consultarStatusZell(id_app: number): Promise<Object> {
    // return await this.http.getNest(`zell/getStatusZell/${id_app}`);
    return await this.http.get('zell/consultar_estatus_zell', { id_app }).toPromise();
  }

  public enviarSMS(solicitante: Solicitantes) {
    let result = this.http.post('solicitudes/enviar_sms', solicitante);
    this.storage.setData('solicitante', solicitante).then(() => { });
    return result;
  }

  public enviarWhatsApp(solicitante: Solicitantes) {
    let result = this.http.post('fresh/sendWhatsapptest', solicitante);
    this.storage.setData('solicitante', solicitante).then(() => { });
    return result;
  }

  async validarSMS(solicitante: Solicitantes): Promise<Object> {
    let result = await this.http.post('solicitudes/validar_sms', solicitante).toPromise();
    return result;
  }
  async guardarVideo(data: FormData, solicitud: Solicitudes, solicitante: Solicitantes) {
    let result = await this.http.postFile('solicitudes/guardar_video', data);
    this._loggerService.saveError(JSON.stringify({ code: result['code']  }), solicitud.id, 'guardar video');
    if (result['code'] == 200) {
      result = await this.crearSolicitud({ solicitud, solicitante });
    }
    return result;
  }
  async guardarFirma(data: Object, solicitud: Solicitudes, solicitante: Solicitantes) {
    let result = await this.http.postFile('solicitudes/guardar_firma', data);
    this._loggerService.saveError(JSON.stringify({ code: result['code']  }), solicitud.id, 'guardar firma');
    if (result['code'] == 200) {
      result = await this.crearSolicitud({ solicitud, solicitante });
    }
    return result;
  }
  async consultarDocumentos(id_solicitud: number, id_solicitante: number): Promise<Documentos[]> {
    let result = await this.http.get('solicitudes/consultar_documentos', { id_solicitud, id_solicitante }).toPromise();
    const consultaDocumentos: ConsultarDocumentos = { data: [] };

    await this._graphQLService.query(`query GetDocuments($idSolicitud: StringToInt!, $idSolicitante: StringToInt!) {
        getDocuments(id_solicitud: $idSolicitud, id_solicitante: $idSolicitante) {
          ok
          code
          data {
            IdDocuments_Application
            State
            CreatedDate
            UpdatedDate
            inZell
            UrlFile
            document {
              Description
            }
          }
        }
      }`, {
      idSolicitud: id_solicitud,
      idSolicitante: id_solicitante
    })
      .then((resp) => {
        const { data, ok } = resp.data['getDocuments']
        if (ok) {
          data.forEach(document => {
            consultaDocumentos.data.push({
              id: document?.IdDocuments_Application,
              solicitante_id: document?.person?.IdPerson?.toString(),
              nombre_archivo: document?.UrlFile,
              titulo: document?.document?.Description,
              fecha: document?.UpdatedDate || document?.CreatedDate || '',
              comentario: null,
              solicitud_id: document?.application?.IdApplication,
              estatus: document?.State
            })
          })
        }
      })

    if (result['code'] == 200) {
      return consultaDocumentos.data;
    } else {
      return [];
    }
  }
  async subirDocumentos(datos: Object): Promise<any> {
    let IdDocument: number;
    switch (datos['tipo']) {
      case '01': IdDocument = 25; break;
      case '02': IdDocument = 6; break;
      case '03': IdDocument = 19; break;
      case '04': IdDocument = 32; break;
      case '05':
        IdDocument = 16;
        datos['titulo'] = 'INE(Por ambos lados)';
        break;
      case '06': IdDocument = 5; break;
      case '07':
        IdDocument = 11;
        datos['titulo'] = 'CLABE o No Tarjeta';
        break;
      case '08': IdDocument = 22; break;
      case '10': IdDocument = 24; break;
      case '11': IdDocument = 9; break;
      case '12':
        IdDocument = 26;
        datos['titulo'] = 'Tabla de amortización';
        break;
      case '13': IdDocument = 2; break;
      case '27': IdDocument = 23; break;
      case '55': IdDocument = 37; break;
      case '56': IdDocument = 36; break;
      case '80': IdDocument = 28; break;
      case 'aut exc': IdDocument = 1; break;
      case 'CFIN': IdDocument = 48; break;
      case 'CLIQ': IdDocument = 3; break;
      case 'ExpCDigSCf': IdDocument = 8; break;
      case 'Fcliente': IdDocument = 15; break;
    }

    const contentType = datos["archivo_nombre"].split(".").pop();

    // * Actualizar Documento en BD ayudate 2.0
    const resultNestJS = await this.http.postNest('Document/awsPutDoc', {
      IdDocument,
      contentType,
      titleDocument: datos['titulo'],
      file: datos['archivo'],
      IdEmployee: datos["id_solicitante"],
      IdApplication: datos["id_solicitud"],
      extencion: contentType,
    });

    let result = {};

    result['msj'] = resultNestJS['msj'];
    result['code'] = resultNestJS['code'];
    result['error'] = resultNestJS['error'];

    return result;
  }

  async cambiarEstatusZell(id_solicitud: number, id_app: number, isRecarga = null): Promise<any> {
    let result = await this.http.post('zell/cambiar_estatus', { id_solicitud, id_app, isRecarga }).toPromise();

    // let resultNest = await this.http.postNest('zell/changeStatusZell', { id_solicitud, id_app, isRecarga });

    return result;
  }

  async cancelarConsignacao(adeNumero: string, isEnomina: boolean = false, id_solicitud: number = 0): Promise<any> {
    return await this.http.post('enomina/cancelar_consignacion', { adeNumero, isEnomina, id_solicitud }).toPromise();
  }

  async eliminarAdeEnomina(id_solicitud: number): Promise<any> {
    return await this.http.post('enomina/eliminar_ade_enomina', { id_solicitud }).toPromise();
  }

  async consultarMargem(body: object): Promise<any> {
    return await this.http.post('enomina/consultar_margen', body).toPromise();
  }

  async consultarConsignacao(body: object): Promise<any> {
    return await this.http.post('enomina/consultar_consignacion', body).toPromise();
  }

  async consultarEstatusZell(id_app: number): Promise<any> {
    return await this.http.get('zell/consultar_estatus_zell', { id_app }).toPromise();

    // return await this.http.getNest('zell/getStatusZell', { IdAppZell: id_app });
  }

  /**
   * 
   * @param {string} bornDate format yyyymmdd
   * @param {string} fullname f_last_name s_last_name name(s)
   * @returns {Promise<any>}
   */
  public async getZellPersonByName(vBirthDate: string, vName: string): Promise<any> {
    // return await this.http.get('zell/consultar_datos_nombre', { vBirthDate, vName }).toPromise();

    return await this.http.postNest('zell/getApplicationByName', { vName, vBirthDate });
  }

  public async getSolictudByPersonId(PersonId: number): Promise<Object> {
    // return await this.http.post('solicitudes/consultar_solicitud_person', { PersonId }).toPromise();

    return await this.http.getNest("zell/getApplicationByPerson", { IdPerson: PersonId })
  }

  /**
   * 
   * @param {number} iPersonId 
   * @returns {Promise<any>}
   */
  public async getZellPersonById(iPersonId: number): Promise<any> {
    // return await this.http.get('zell/consultar_datos_id', { iPersonId }).toPromise();
    return await this.http.getNest("zell/getApplicationByPerson", { IdPerson: iPersonId })
  }

  /**
   * 
   * @param {number} idSolicitante 
   * @param {number} idSolicitud 
   * @param {number} idApp 
   * @returns 
   */
  public async uploadZellDocs(idSolicitante: number, idSolicitud: number, idApp: number): Promise<any> {
    try {
      return await this.http.post('zell/subir_archivos_zell', { idSolicitante, idSolicitud, idApp }).toPromise();
    } catch (error) {
      return error;
    }
  }

  validateFirmaStep(step: string) {
    if (step != 'CONTRATO FIRMADO') {
      let steps = JSON.parse(step);
      return (steps.firma != null && steps.firma > 1 && steps.nav == 6);
    }
    return false;
  }

  public getFirmaStep(step: string): string {
    let stepContrato = JSON.parse(step);
    stepContrato.firma = 1
    return JSON.stringify(stepContrato);
  }

  public getVideoAceptoStep(step: string): string {
    let stepContrato = JSON.parse(step);
    stepContrato.firma = 2
    return JSON.stringify(stepContrato);
  }

  public async consultarFichaNomipay(rfc: string, idSolicitud: number, convenio_id: number = 0) {
    return await this.http.post('nomipay/consultar_ficha', { rfc, idSolicitud, convenio_id }).toPromise();
  }

  public async subirCartaAcceptacion(
    file: string,
    identificacionOficial: string,
    rfc: string,
    ficha: string,
    nombre: string,
    idSolicitud: number,
    idSolicitante: number
  ) {
    try {
      return await this.http.post('nomipay/subir_cartaAutorizacion',
        {
          file,
          identificacionOficial,
          rfc,
          ficha,
          nombre,
          idSolicitud,
          idSolicitante
        }).toPromise();
    } catch (error) {
      return error;
    }
  }

  public async getContractToSign(params, save = false) {
    return await this.http.postNest(`credit-request/contract/${save}`, params)
  }

}
